import React from "react";
import PropTypes from "prop-types";
import ShowEyeSvg from "../../assets/svgs/ShowEyeSvg";
import HideEyeSvg from "../../assets/svgs/HideEyeSvg";
import { INPUT_TYPES } from "../../constants";

import styles from "./index.module.scss";

const Input = ({
    placeholder,
    type = "text",
    onChange,
    value,
    name,
    label,
    error = "",
    showEyeIcon = false,
    handleEyeIconClick = null
}) => {
    return (
        <div className={styles.Container}>
            <label htmlFor={name} className={styles.Label}>
                {label}
            </label>
            <div className={styles.InputContainer}>
                <input
                    className={`${styles.Input} ${error && styles.Error}`}
                    type={type}
                    placeholder={placeholder}
                    onChange={onChange}
                    value={value}
                    name={name}
                    id={name}
                />
                {showEyeIcon && (
                    <div className={styles.EyeIconContainer} onClick={handleEyeIconClick}>
                        {type === INPUT_TYPES.PASSWORD ? <HideEyeSvg /> : <ShowEyeSvg />}
                    </div>
                )}
            </div>
        </div>
    );
};

Input.propTypes = {
    placeholder: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    handleEyeIconClick: PropTypes.func,
    error: PropTypes.string,
    type: PropTypes.string,
    showEyeIcon: PropTypes.bool
};

export default Input;
